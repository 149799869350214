<template>
  <v-container fluid>
    <template v-if="loading">
      <loading :text="$t('textTemplates.loading')" />
    </template>
    <template v-else>
      <v-card class="pa-4">
        <v-row class="pa-3">
          <v-card-title class="ma-0"> {{ $t('textTemplates.heading') }}</v-card-title>
          <v-spacer />
          <v-btn rounded color="primary" class="my-4" @click="createTemplate">{{
            $t('textTemplates.createNew')
          }}</v-btn>
        </v-row>
        <p class="mx-4">{{ $t('textTemplates.intro') }}</p>

        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="fal fa-search"
            :label="$t('textTemplates.search')"
            single-line
            hide-details
          />
        </v-card-title>

        <v-data-table
          :headers="templatesHeaders"
          :items="templates"
          class="elevation-1 pointer"
          :search="search"
          :items-per-page="-1"
          :footer-props="{
            itemsPerPageText: $t('rowCount'),
            pageText: '{0}-{1} ' + $t('of') + ' {2}',
            itemsPerPageAllText: $t('all'),
          }"
          @click:row="openTemplate"
        >
          <template #[`item.countryCodes`]="{ item }">
            <div v-for="(code, index) in item.countryCodes" :key="index" class="flags">
              <rounded-flag :country="code" size="30px" />
            </div>
          </template>
        </v-data-table>
        <!-- <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
          />
        </div> -->
      </v-card>
    </template>
  </v-container>
</template>
<script>
import RoundedFlag from '@/components/RoundedFlag.vue';
import Loading from '@/components/Loading.vue';
export default {
  name: 'TextTemplates',
  components: {
    RoundedFlag,
    Loading,
  },
  data() {
    return {
      loading: true,
      search: '',
    };
  },
  computed: {
    templatesHeaders() {
      return [
        { text: this.$t('textTemplates.name'), value: 'name' },
        { text: this.$t('textTemplates.message'), value: 'defaultMessage' },
        {
          text: this.$t('textTemplates.country'),
          value: 'countryCodes',
          cellClass: 'flags',
          sortable: false,
          filterable: false,
        },
      ];
    },
    templates() {
      return this.$store.state.textTemplate.templates;
    },
    pageCount() {
      return Math.ceil(this.templates.length / this.itemsPerPage);
    },
  },

  mounted() {
    this.getTextTemplates();
  },
  methods: {
    async getTextTemplates() {
      try {
        await this.$store.dispatch('textTemplate/getTextTemplates');
        this.loading = false;
      } catch (error) {
        if (!error.hasBeenHandled) {
          alert(error);
        }
      }
    },
    openTemplate(template) {
      this.$router.push({ name: 'TextTemplate', params: { id: template.id } });
    },
    createTemplate() {
      this.$router.push({ name: 'TextTemplate' });
    },
  },
};
</script>
<style lang="scss" scoped>
.flags {
  padding: 0px 8px 0 0px;
  display: inline-block;
}
</style>
